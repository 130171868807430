import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Lottie from 'react-lottie'
import { ButtonLink, Heading, LayoutFixed, Subtitle, Title } from 'ui'
import { Header } from 'features/customers/redeem/components'

import styles from './Thanks.module.scss'
import animationData from './Thanks.json'

export const Thanks = () => {
  const navigate = useNavigate()
  const url = useSelector(state => state.redeem.save.url)

  useEffect(() => {
    if (!url) navigate('/redeem')
  }, []) // eslint-disable-line

  return (
    <LayoutFixed
      title={'Merci pour votre commande !'}
      nav={<Header />}
      opposite={true}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <Title level={1}>Merci !</Title>
        </div>
        <div className={styles.inner}>
          <div className={styles.anim}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={300}
              width={300}
            />
          </div>
          <div className={styles.text}>
            <div className={styles.subtitle}>
              <Heading level={2}>Votre commande est en préparation</Heading>
            </div>
            <div className={styles.block}>
              Les produits que vous avez sélectionné vont être préparés et
              expédiés par nos artisans partenaires, et vous en serez bien-sûr
              informé.
            </div>
            <div className={styles.block}>
              Nous vous souhaitons une excellente découverte, n'hésitez pas à
              nous tenir informés de la qualité de votre expérience !
            </div>
            <div className={styles.block}>L'équipe Ici Présent!</div>
            <div className={styles.ctas}>
              <div className={styles.cta}>
                <ButtonLink to={'/redeem/validation'}>
                  J'ai un autre coffret
                </ButtonLink>
              </div>
              <div className={styles.cta}>
                <ButtonLink primary to={url || ''}>
                  Suivre ma livraison
                </ButtonLink>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.retarg}>
          <Subtitle>Ce calendrier pourrait vous intéresser ?</Subtitle>
          <div className={styles.retargItem}>
            <img
              className={styles.retargPic}
              src="https://www.icipresent.com/cdn/shop/files/CDA_BAYLE_1_800x.png?v=1726145481"
              alt="Calendrier de l'Avent Saucisson 2024"
            />
            <div className={styles.retargContent}>
              <Heading className={styles.retargTitle}>
                Calendrier de l'Avent Saucisson 2024
              </Heading>
              <div className={styles.retargDesc}>
                Le plus original ! Craquez pour ce calendrier bon-vivant,
                confectionné par Maison Bayle, Champion du Monde de Boucherie.
              </div>
              <div className={styles.retargFooter}>
                <div className={styles.retargPrice}>
                  39.85 € au lieu de{' '}
                  <span className={styles.retargOldPrice}>46.90 €</span>
                </div>
                <ButtonLink
                  external
                  primary
                  to="https://www.icipresent.com/products/calendrier-de-saucisson-cree-par-un-champion-du-monde-de-la-boucherie?utm_source=appredeem"
                >
                  Découvrez-en plus
                </ButtonLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutFixed>
  )
}
